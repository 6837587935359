import '@splidejs/splide/dist/css/splide.min.css';
import React from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { PaginationResponse } from "@/domain/general.interface";
import { ReactNode, useRef, useState } from "react";
import { FileElement, UserAlbum } from "@/domain/account/galleries.interface";
import { useParams } from "next/navigation";
import { useRouter } from 'next-nprogress-bar';
import Button from "@apg.gg/core/lib/Button";
import Image from "next/image";
import MiniSlider from "../MiniSlider";
import TrashIcon from "@apg.gg/icons/lib/TrashIcon";
import VisibilityIcon from "@apg.gg/icons/lib/VisibilityIcon";
import VisibilityOffIcon from "@apg.gg/icons/lib/VisibilityOffIcon";
import FloatingActions, { TooltipPosition } from "../FloatingActions/FloatingActions";
import PinFillIcon from '@apg.gg/icons/lib/PinFillIcon';
import PinOutlineIcon from '@apg.gg/icons/lib/PinOutlineIcon';
import Drawer from "@apg.gg/core/lib/Drawer";
import useWindowSize from '@apg.gg/core/lib/useWindowSize';
import Link from 'next/link';
import TextLinker from '@apg.gg/core/lib/TextLinker';
import { RawDraftContentState } from 'draft-js';
import MoreIcon from '@apg.gg/icons/lib/MoreIcon';
import { useTranslations } from 'next-intl';

interface GalleriesProps {
  data: PaginationResponse<UserAlbum>;
  extras?: ReactNode,
  isEditing?: boolean;
  onDelete?: (userAlbum: UserAlbum) => void;
  onActive?: (userAlbum: UserAlbum) => void;
  onPinned?: (userAlbum: UserAlbum) => void;
}

const Galleries: React.FC<GalleriesProps> = ({ data, extras, isEditing, onDelete, onActive, onPinned }) => {
  const { isSm, isXs } = useWindowSize();
  const router = useRouter();
  const params = useParams();
  const { locale, profile } = params;
  const t = useTranslations('Profile');
  const {
    items: userAlbums,
    hasNextPage
  } = data || {};

  const [showAlbum, setShowAlbum] = useState(false);
  const [selectedAlbum, setSelectedAlbum] = useState<UserAlbum | null>(null);

  const imageSliderRef = useRef<any>(null);
  const imageSliderOptions = {
    type: 'slide',
    height: 'auto',
    width: '700px',
    pagination: false,
    gap: '0rem',
    perPage: 1,
    perMove: 1,
  };

  const getActions = (userAlbum: UserAlbum) => {
    const { isActive, isPinned } = userAlbum;
    const iconClassname = "text-black text-base p-2";

    return [
      {
        type: 'pinned',
        icon: isPinned ? <PinFillIcon className={iconClassname} /> : <PinOutlineIcon className={iconClassname} />,
        text: isPinned ? t('label-no-pinned') : t('label-pinned'),
        tooltipPositon: "left" as TooltipPosition
      },
      {
        type: "active",
        icon: isActive ? <VisibilityOffIcon className={iconClassname} /> : <VisibilityIcon className={iconClassname} />,
        text: isActive ? t("label-hide") : t("label-show"),
        tooltipPositon: "left" as TooltipPosition
      },
      {
        type: 'delete',
        icon: <TrashIcon className={iconClassname} />,
        text: t('label-delete'),
        tooltipPositon: "left" as TooltipPosition
      }
    ];
  }

  const hasRichDescription = (shortRichDescription: RawDraftContentState) => {
    if (!shortRichDescription || shortRichDescription === undefined) return false;

    if ('blocks' in shortRichDescription && 'entityMap' in shortRichDescription) {
      // Verificar si blocks es un array con al menos un elemento
      if (Array.isArray(shortRichDescription.blocks) && shortRichDescription.blocks.length > 0) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      <div className="flex flex-col bg-black-900 p-4 lg:p-8 gap-6 rounded-2xl items-center">
        {/* Header section */}
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col">
            <h1 className="text-white text-title-lg uppercase">{t('section-galleries-title')}</h1>
            <span className="gradient-1 h-2 w-24 rounded-2xl"></span>
          </div>
          {extras}
        </div>

        {/* Content section */}
        <div className="flex flex-col w-full items-center justify-center gap-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full justify-start gap-6">
            {userAlbums?.map((userAlbum, index) => {
              const { album } = userAlbum;
              const { files, name } = album;
              const miniSliderImages = files?.slice(0, 4);

              return (
                <div key={`album-${album.id}-${index}`} className="group relative bg-black rounded-2xl overflow-hidden">
                  {isEditing ? (
                    <FloatingActions
                      className="absolute top-2 right-2"
                      actions={getActions(userAlbum)}

                      onAction={(type) => {
                        if (type === 'delete') onDelete?.(userAlbum);
                        if (type === 'active') onActive?.(userAlbum);
                        if (type === 'pinned') onPinned?.(userAlbum);
                      }}
                    />
                  ) : null}
                  <div 
                    className="cursor-pointer"
                    role="button"
                    onClick={() => {
                      setShowAlbum(true);
                      setSelectedAlbum(userAlbum);
                    }}
                  >
                    <MiniSlider images={miniSliderImages} />
                    <div className="flex py-4 justify-center">
                      <h4 className="text-body-lg text-white">{name}</h4>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          {hasNextPage ? (
            <Button
              className="border-white text-white"
              type="outline"
              icon={<MoreIcon />}
              iconSize="xl"
              onClick={() => router.push(`${isEditing ? 'editor' : profile}/galleries`)}
            >
              {t('label-show-all')}
            </Button>
          ) : null}
        </div>
      </div>

      {showAlbum ? (
        <Drawer 
          isOpen={showAlbum}
          onClose={() => setShowAlbum(false)}
          disableClickOutsideToClose={false}
          position="center"
          maxHeight="100%"
          header={
            <h3 className="text-title-md md:text-headline-sm">{selectedAlbum?.album.name}</h3>
          }
          classNames={{
            wrapper: 'max-w-4xl md:w-[36rem] lg:w-[56rem]',
          }}
          content={
            <div className="flex flex-col lg:flex-row gap-4 w-full h-4/5">
              <div className="flex flex-auto max-w-[900px] max-h-[900px] aspect-square">
                <Splide options={imageSliderOptions} ref={imageSliderRef} className="w-full">
                  {selectedAlbum?.album.files.map((fileElement: FileElement, index) => {
                    return (
                      <SplideSlide key={index} className="flex flex-col gap-4 max-w-[900px] max-h-[900px]">
                        <Image src={fileElement.file.url} className="max-w-[900px] max-h-[900px] aspect-square object-contain bg-black w-full h-full" alt='' width={600} height={600} />
                      </SplideSlide>
                    )
                  })}
                </Splide>
              </div>
              <div className="w-full lg:max-w-[320px] min-w-[320px]">
                <div className="flex gap-3 py-4">
                  <Image className="rounded-full" src={`${selectedAlbum?.user?.profileImage}`} alt={selectedAlbum?.user?.username || ''} width={40} height={40} />
                  <div className="flex flex-col justify-center">
                    <h4 className="text-label-lg text-white">
                      {selectedAlbum?.user?.username || ''}
                    </h4>
                    <Link href={`/${selectedAlbum?.user?.username}`} className="text-label-md text-black-500" onClick={() => setShowAlbum(false)}>
                      apg.gg/{selectedAlbum?.user?.username || ''}
                    </Link>
                  </div>
                </div>
                <div className="flex w-full mb-4">
                  {hasRichDescription(selectedAlbum?.album.descriptionRich as RawDraftContentState) ? (
                    <TextLinker content={selectedAlbum?.album.descriptionRich} linkComponent={Link} locale={locale as string} />
                  ) : (
                    <TextLinker text={`${selectedAlbum?.album.description}`} linkComponent={Link} locale={locale as string} />
                  )}
                </div>
              </div>
            </div>
          }
        />
      ) : null}
    </>
  );
};

export default Galleries;
