"use client";

import { ReactNode } from "react";
import Link from "next/link";
import { RawDraftContentState } from "draft-js";
import { useTranslations } from "next-intl";
import { useParams } from "next/navigation";
import TextLinker from "@apg.gg/core/lib/TextLinker";

interface AboutProps {
  data: RawDraftContentState | string;
  extras?: ReactNode
}

const About: React.FC<AboutProps> = ({ data: aboutMe, extras }) => {
  const t = useTranslations('Profile');
  const params = useParams();
  const { locale } = params;

  return (
    <div className="flex flex-col bg-black-900 p-4 lg:p-8 gap-6 rounded-2xl items-center">
      {/* Header section */}
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col">
          <h1 className="text-white text-title-lg uppercase">{t('section-about-title')}</h1>
          <span className="gradient-1 h-2 w-24 rounded-2xl"></span>
        </div>
        {extras}
      </div>

      {/* Content section */}
      <div className="flex w-full">
        {typeof aboutMe === 'string' ? (
          <div className="text-white text-body-lg" dangerouslySetInnerHTML={{
            __html: aboutMe
          }}></div>
        ) : null}

        {typeof aboutMe !== 'string' ? (
          <div className="text-white text-body-lg">
            <TextLinker
              content={aboutMe}
              linkComponent={Link}
              locale={locale as string}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default About;
