import { ReactNode } from "react";
import { UserVideo } from "@/domain/account/videos.interface";
import OneVideo from "./VideoStyle/OneVideo";
import ThreeVideo from "./VideoStyle/ThreeVideo";
import ListWithThumbs from "./VideoStyle/ListWithThumbs";
import OnlyList from "./VideoStyle/OnlyList";
import { useTranslations } from "next-intl";

interface VideosProps {
  data: UserVideo;
  extras?: ReactNode
}

const Videos: React.FC<VideosProps> = ({ data: videos, extras }) => {
  const t = useTranslations('Profile');
  const { youtubeChannel } = videos || {};
  const { template, channelId } = youtubeChannel || {};
  
  return (
    <div className="flex flex-col bg-black-900 p-4 lg:p-8 gap-6 rounded-2xl items-center">
      {/* Header section */}
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col">
          <h1 className="text-white text-title-lg uppercase">{t('section-videos-title')}</h1>
          <span className="gradient-1 h-2 w-24 rounded-2xl"></span>
        </div>
        {extras}
      </div>

      {/* Content section */}
      <div className="flex w-full">
        {template === 'oneVideo' ? <OneVideo channelId={channelId} /> : null}
        {template === 'threeVideo' ? <ThreeVideo channelId={channelId} /> : null}
        {template === 'listWithThumbs' ? <ListWithThumbs channelId={channelId} /> : null}
        {template === 'onlyList' ? <OnlyList channelId={channelId} /> : null}
      </div>
    </div>
  );
};

export default Videos;
