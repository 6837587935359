import React, { FC, useEffect, useState } from "react";
import { VideoViewProps, YoutubeVideo } from "@/domain/account/videos.interface";
import YoutubeEmbed from "@/components/YoutubeEmbed/YoutubeEmbed";
import axios, { AxiosError } from "axios";
import { RequestError } from "@/graph/general.endpoints";
import { ERROR_CODES } from "@/lib/utils/constants";
import { toast } from "react-toastify";
import PulseRingIcon from '@apg.gg/icons/lib/PulseRingIcon';
import { useTranslations } from "next-intl";

const OneVideo: FC<VideoViewProps> = ({
  channelId,
}: VideoViewProps) => {
  const t = useTranslations('Profile');
  const [isFetching, setIsFetching] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<YoutubeVideo | null>(null);

  const getVideos = async () => {
    setIsFetching(true);

    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json"
        }
      };
  
      const response = await axios(`${process.env.NEXT_PUBLIC_API_PLATFORM_URL}/youtube/${channelId}`, requestOptions);
  
      setSelectedVideo(response.data.items[0]);
    } catch (error: AxiosError<RequestError> | any) {
      const errorCode = (error as AxiosError<RequestError>)?.response?.data?.code as number;
      const errorMessage = ERROR_CODES(t)[errorCode];
      toast.error(errorMessage || error?.message || error?.response?.statusText);
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    getVideos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!selectedVideo) return null;

  return (
    <div className="flex gap-4 w-full">
      {isFetching ? (
        <div className="flex flex-1 justify-center items-center">
          <PulseRingIcon className="text-blue text-6xl" />
        </div>
      ) : (
        <>
          {selectedVideo ? (
            <div className="flex rounded-2xl overflow-hidden flex-1">
              <YoutubeEmbed youtubeLink={selectedVideo.link} />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default React.memo(OneVideo);
