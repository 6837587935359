import { UserGame } from "@/domain/account/profile.interface";
import Tooltip from "@apg.gg/core/lib/Tooltip";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { FC, useState } from "react";
import { IoIosCopy } from "react-icons/io";

type GameCardProps = {
  userGame: UserGame;
}

const GameCard: FC<GameCardProps> = ({ userGame }) => {
  const t = useTranslations('Profile');
  const { game } = userGame;
  const [tooltipText, setTooltipText] = useState(t('copy-playertag'));

  const handleCopyToClipboard = () => {
    // Copiar el valor al portapapeles
    navigator.clipboard.writeText(userGame.playerTag);

    // Cambiar el texto del Tooltip a "Copiado"
    setTooltipText(t('copied'));

    // Restaurar el texto del Tooltip después de un tiempo
    setTimeout(() => {
      setTooltipText(t('copy-playertag'));
    }, 1500); // Cambia esto según cuánto tiempo quieres que se muestre "Copiado"
  };

  return (
    <div className="flex flex-col items-center gap-2 relative w-full">
      <Image src={game.thumbnail} alt={game.name} width={360} height={480} className="rounded-2xl object-cover object-center w-full h-full lg:w-[200px] lg:h-[255px]" />
      <div className="flex flex-col items-center" role="button" onClick={handleCopyToClipboard}>
        <Tooltip text={tooltipText} placement="top">
          <div className="flex gap-1 items-center">
            <h4 className="text-white text-body-lg text-center font-bold mb-0">{userGame.playerTag}</h4>
            <IoIosCopy className="text-xs text-white-300" />
          </div> 
        </Tooltip>
        <span className="text-white-300 text-body-sm text-center">{userGame.clasification.name}</span>
      </div>
    </div>
  )
}

export default GameCard;