import { useRef } from "react";
import { UserAchievement } from "@/domain/account/profile.interface";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import useWindowSize from "@apg.gg/core/lib/useWindowSize";

import "@splidejs/splide/dist/css/splide.min.css";
import FloatingActions from "../FloatingActions";
import CardAchievement from "@apg.gg/core/lib/CardAchievement";
import { useParams } from "next/navigation";
import Link from "next/link";

interface AchievementsCarouselProps {
  userAchivements: UserAchievement[];
  isEditing: boolean;
  getActions: (userAchievement: UserAchievement) => any;
  onEdit: (userAchievement: UserAchievement) => void;
  onDelete: (userAchievement: UserAchievement) => void;
  onActive: (userAchievement: UserAchievement) => void;
  onFeature: (userAchievement: UserAchievement) => void;
}

const AchievementsCarousel: React.FC<AchievementsCarouselProps> = ({ 
  userAchivements, 
  isEditing,
  getActions,
  onEdit,
  onDelete,
  onActive,
  onFeature
}) => {
  const { locale } = useParams();
  const { isXs, isSm } = useWindowSize();
  const mainSliderRef = useRef<any>(null);

  const mainSliderOptions = {
    type: "slide",
    height: "435px",
    width: "100%",
    fixedWidth: isXs ? "85%" : isSm ? "290px" : "290px",
    pagination: false,
    gap: "1rem",
    perPage: isXs ? 1 : isSm ? 1 : 4,
    perMove: 1,
    padding: "0"
  };

  return (
    <div className="flex w-full">
      <Splide options={mainSliderOptions} ref={mainSliderRef} className="games-carousel">
        {userAchivements?.map((userAchivement, index) => {
          const { achievement, achievement: { game } } = userAchivement;

          return (
            <SplideSlide key={`game-slide-${achievement.id}-${index}`} className="cursor-pointer overflow-hidden">
              <div className="group relative overflow-hidden rounded-2xl">
                {isEditing ? (
                  <FloatingActions
                    className="absolute top-2 right-2"
                    actions={getActions(userAchivement)}
                    onAction={(type) => {
                      if (type === 'edit') onEdit(userAchivement);
                      if (type === 'delete') onDelete(userAchivement);
                      if (type === 'active') onActive(userAchivement);
                      if (type === 'feature') onFeature(userAchivement);
                    }}
                  />
                ) : null}
                <CardAchievement
                  className="bg-black"
                  id={String(achievement.id)}
                  title={achievement.title}
                  shortDescription={achievement.description}
                  shortRichDescription={achievement.descriptionRich}
                  banner={achievement.image}
                  link={achievement.link}
                  date={new Date(achievement.date).toISOString()}
                  game={game.name}
                  locale={locale as string}
                  linkComponent={Link}
                />
              </div>
            </SplideSlide>
          )
        })}
      </Splide>
    </div>
  );
};

export default AchievementsCarousel;
