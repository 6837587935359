import { useRef } from "react";
import { Event } from "@/domain/account/events.interface";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import useWindowSize from "@apg.gg/core/lib/useWindowSize";

import "@splidejs/splide/dist/css/splide.min.css";
import FloatingActions from "../FloatingActions";
import CardAchievement from "@apg.gg/core/lib/CardAchievement";
import { useParams } from "next/navigation";
import Link from "next/link";
import CardEvent from "@apg.gg/core/lib/CardEvent";

interface EventsCarouselProps {
  events: Event[]
  isEditing: boolean;
  getActions: (event: Event) => any;
  onEdit: (event: Event) => void;
  onDelete: (event: Event) => void;
  onActive: (event: Event) => void;
  onFeature: (event: Event) => void;
}

const EventsCarousel: React.FC<EventsCarouselProps> = ({ 
  events, 
  isEditing,
  getActions,
  onEdit,
  onDelete,
  onActive,
  onFeature
}) => {
  const { locale } = useParams();
  const { isXs } = useWindowSize();
  const mainSliderRef = useRef<any>(null);

  const mainSliderOptions = {
    type: "slide",
    height: "435px",
    width: isXs ? "100%" : "auto",
    fixedWidth: isXs ? "85%" : "290px",
    pagination: false,
    gap: "1rem",
    perPage: isXs ? 1 : 2,
    perMove: 1,
    padding: 0
  };

  return (
    <div className="flex w-full">
      <Splide options={mainSliderOptions} ref={mainSliderRef} className="games-carousel">
        {events?.map((event, index) => {
          const { game, eventType, eventTag, eventCategory, profileType, description } = event;

          return (
            <SplideSlide key={`game-slide-${event.id}-${index}`} className="cursor-pointer overflow-hidden">
              <div className="group relative overflow-hidden rounded-2xl">
                {isEditing ? (
                  <FloatingActions
                    className="absolute top-2 right-2"
                    actions={getActions(event)}
                    onAction={(type) => {
                      if (type === 'edit') onEdit(event);
                      if (type === 'delete') onDelete(event);
                      if (type === 'active') onActive(event);
                      if (type === 'feature') onFeature(event);
                    }}
                  />
                ) : null}
                <CardEvent
                  className="bg-black"
                  id={event.id}
                  title={event.title}
                  slug={event.slug}
                  banner={event.image}
                  link={event.link || undefined}
                  shortDescription={'a'}
                  shortRichDescription={description}
                  date={new Date(event.date).toISOString()}
                  game={game ? game.name : 'General'}
                  profileType={profileType}
                  type={eventType.name}
                  tag={eventTag.name}
                  category={eventCategory.name}
                  locale={locale}
                  owner={event.owner}
                  linkComponent={Link}
                />
              </div>
            </SplideSlide>
          )
        })}
      </Splide>
    </div>
  );
};

export default EventsCarousel;
