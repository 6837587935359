import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import Button from "@apg.gg/core/lib/Button";
import Tooltip from "@apg.gg/core/lib/Tooltip";

export type TooltipPosition = 'top' | 'bottom' | 'left' | 'right' | undefined;

export interface ActionProps {
  type: string;
  icon: ReactNode;
  text: string;
  tooltipPositon: TooltipPosition;
}

export interface FloatingActionsProps {
  className: string;
  actions: ActionProps[];
  onAction: (actionType: string) => void;
}

const FloatingActions: FC<FloatingActionsProps> = ({
  className,
  actions,
  onAction
}: FloatingActionsProps) => {
  return (
    <>
      <div className={classNames(
        "z-[60] flex-col gap-2 flex opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out",
        className
      )}>
        {actions && actions.map((action, index) => {
          return (
            <Tooltip key={index} placement={action.tooltipPositon} text={action.text}>
              <Button
                icon={action.icon}
                onClick={() => onAction(action.type)}
                className="w-6 h-6 rounded-full bg-white-100 hover:bg-white-200 text-black-900"
              />
            </Tooltip>
          )
        })}
      </div>
    </>
  )
};

export default React.memo(FloatingActions);
