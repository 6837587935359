import { ReactNode } from "react";
import { UserAchievement } from "@/domain/account/profile.interface";
import CardAchievement from '@apg.gg/core/lib/CardAchievement';
import Button from "@apg.gg/core/lib/Button";
import { PaginationResponse } from "@/domain/general.interface";
import { useParams } from "next/navigation";
import { useRouter } from 'next-nprogress-bar';
import EditIcon from "@apg.gg/icons/lib/EditIcon";
import StarFillIcon from "@apg.gg/icons/lib/StarFillIcon";
import StarOutlineIcon from "@apg.gg/icons/lib/StarOutlineIcon";
import TrashIcon from "@apg.gg/icons/lib/TrashIcon";
import VisibilityIcon from "@apg.gg/icons/lib/VisibilityIcon";
import VisibilityOffIcon from "@apg.gg/icons/lib/VisibilityOffIcon";
import FloatingActions, { TooltipPosition } from "../FloatingActions/FloatingActions";
import MoreIcon from "@apg.gg/icons/lib/MoreIcon";
import { useTranslations } from "next-intl";
import Link from "next/link";
import AchievementsCarousel from "./AchievementsCarousel";

interface AchievementsProps {
  data: PaginationResponse<UserAchievement>;
  extras?: ReactNode,
  isEditing?: boolean;
  onEdit?: (achievement: UserAchievement) => void;
  onDelete?: (achievement: UserAchievement) => void;
  onActive?: (achievement: UserAchievement) => void;
  onFeature?: (achievement: UserAchievement) => void;
}

const Achievements: React.FC<AchievementsProps> = ({ data, extras, isEditing, onEdit, onDelete, onActive, onFeature }) => {
  const router = useRouter();
  const params = useParams();
  const { profile, locale } = params;
  const t = useTranslations('Profile');
  const { 
    items: userAchivements,
    hasNextPage
  } = data || {};

  const getActions = (userAchievement: UserAchievement) => {
    const { isActive, isFeature } = userAchievement;
    const iconClassname = "text-black text-base p-2";

    return [
      {
        type: 'edit',
        icon: <EditIcon className={iconClassname} />,
        text: t('label-edit'),
        tooltipPositon: "left" as TooltipPosition
      },
      {
        type: 'feature',
        icon: isFeature ? <StarFillIcon className={iconClassname} /> : <StarOutlineIcon className={iconClassname} />,
        text: isFeature ? t('label-no-featured') : t('label-featured'),
        tooltipPositon: "left" as TooltipPosition
      },
      {
        type: "active",
        icon: isActive ? <VisibilityOffIcon className={iconClassname} /> : <VisibilityIcon className={iconClassname} />,
        text: isActive ? t("label-hide") : t("label-show"),
        tooltipPositon: "left" as TooltipPosition
      },
      {
        type: 'delete',
        icon: <TrashIcon className={iconClassname} />,
        text: t('label-delete'),
        tooltipPositon: "left" as TooltipPosition
      }
    ];
  }

  return (
    <div className="flex flex-col bg-black-900 p-4 lg:p-8 gap-6 rounded-2xl items-center">
      {/* Header section */}
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col">
          <h1 className="text-white text-title-lg uppercase">{t('section-achievements-title')}</h1>
          <span className="gradient-1 h-2 w-24 rounded-2xl"></span>
        </div>
        {extras}
      </div>

      {/* Content section */}
      <div className="flex flex-col w-full items-center justify-center gap-4">
        <div className="hidden lg:grid lg:grid-cols-3 w-full justify-start gap-6">
          {userAchivements?.map((userAchivement, index) => {
            const { achievement, achievement: { game } } = userAchivement;
            return (
              <div key={`achievement-${achievement.id}-${index}`} className="group relative overflow-hidden rounded-2xl">
                {isEditing ? (
                  <FloatingActions
                    className="absolute top-2 right-2"
                    actions={getActions(userAchivement)}
                    onAction={(type) => {
                      if (type === 'edit') onEdit?.(userAchivement);
                      if (type === 'delete') onDelete?.(userAchivement);
                      if (type === 'active') onActive?.(userAchivement);
                      if (type === 'feature') onFeature?.(userAchivement);
                    }}
                  />
                ) : null}
                <CardAchievement
                  className="bg-black"
                  id={String(achievement.id)}
                  title={achievement.title}
                  shortDescription={achievement.description}
                  shortRichDescription={achievement.descriptionRich}
                  banner={achievement.image}
                  link={achievement.link}
                  date={new Date(achievement.date).toISOString()}
                  game={game.name}
                  locale={locale as string}
                  linkComponent={Link}
                />
              </div>
            )
          })}
        </div>
        <div className="flex w-full lg:hidden">
          <AchievementsCarousel
            userAchivements={userAchivements}
            isEditing={isEditing || false}
            getActions={getActions}
            onEdit={(userAchievement) => onEdit?.(userAchievement)}
            onDelete={(userAchievement) => onDelete?.(userAchievement)}
            onActive={(userAchievement) => onActive?.(userAchievement)}
            onFeature={(userAchievement) => onFeature?.(userAchievement)}
          />
        </div>
        {hasNextPage ? (
          <Button
            type="outline"
            fontSize="sm"
            onClick={() => router.push(`${isEditing ? 'editor' : profile}/achievements`)}
          >
            {t('label-show-all')}
          </Button>
        ) : null }
      </div>
    </div>
  );
};

export default Achievements;
