import Button from '@apg.gg/core/lib/Button';
import PlusIcon from '@apg.gg/icons/lib/PlusIcon';

interface EmptySectionProps {
  name: string;
  openModal: () => void;
}

const EmptySection: React.FC<EmptySectionProps> = ({ name, openModal }) => {
  return (
    <div className="flex bg-black-900 p-4 lg:p-8 gap-3 rounded-2xl items-center">
      <Button
        type="primary"
        icon={<PlusIcon />}
        onClick={() => openModal()}
      ></Button>
      <h5 className="card-title">{name}</h5>
    </div>
  );
};

export default EmptySection;
