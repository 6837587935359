import { ReactNode } from "react";
import Button from "@apg.gg/core/lib/Button";
import { PaginationResponse } from "@/domain/general.interface";
import { useParams } from "next/navigation";
import { useRouter } from 'next-nprogress-bar';
import { UserPartner } from "@/domain/account/partners.interface";
import Link from "next/link";
import Image from "next/image";
import FloatingActions from "../FloatingActions";
import { TooltipPosition } from "../FloatingActions/FloatingActions";
import TrashIcon from "@apg.gg/icons/lib/TrashIcon";
import EditIcon from '@apg.gg/icons/lib/EditIcon';
import VisibilityOffIcon from "@apg.gg/icons/lib/VisibilityOffIcon";
import StarFillIcon from "@apg.gg/icons/lib/StarFillIcon";
import StarOutlineIcon from "@apg.gg/icons/lib/StarOutlineIcon";
import VisibilityIcon from "@apg.gg/icons/lib/VisibilityIcon";
import MoreIcon from "@apg.gg/icons/lib/MoreIcon";
import { useTranslations } from "next-intl";

interface PartnersProps {
  data: PaginationResponse<UserPartner>;
  extras?: ReactNode,
  isEditing?: boolean;
  onEdit?: (partner: UserPartner) => void;
  onDelete?: (partner: UserPartner) => void;
  onActive?: (partner: UserPartner) => void;
  onFeature?: (partner: UserPartner) => void;
}

const Partners: React.FC<PartnersProps> = ({ data, extras, isEditing, onEdit, onDelete, onActive, onFeature }) => {
  const router = useRouter();
  const params = useParams();
  const { profile } = params;
  const t = useTranslations('Profile');
  const { 
    items: userPartners,
    hasNextPage
  } = data || {};

  const getActions = (userPartner: UserPartner) => {
    const { isActive, isFeature } = userPartner;
    const iconClassname = "text-black text-base p-2";

    return [
      {
        type: 'edit',
        icon: <EditIcon className={iconClassname} />,
        text: t('label-edit'),
        tooltipPositon: "left" as TooltipPosition
      },
      {
        type: 'feature',
        icon: isFeature ? <StarFillIcon className={iconClassname} /> : <StarOutlineIcon className={iconClassname} />,
        text: isFeature ? t('label-no-featured') : t('label-featured'),
        tooltipPositon: "left" as TooltipPosition
      },
      {
        type: "active",
        icon: isActive ? <VisibilityOffIcon className={iconClassname} /> : <VisibilityIcon className={iconClassname} />,
        text: isActive ? t("label-hide") : t("label-show"),
        tooltipPositon: "left" as TooltipPosition
      },
      {
        type: 'delete',
        icon: <TrashIcon className={iconClassname} />,
        text: t('label-delete'),
        tooltipPositon: "left" as TooltipPosition
      }
    ];
  }

  return (
    <div className="flex flex-col bg-black-900 p-4 lg:p-8 gap-6 rounded-2xl items-center">
      {/* Header section */}
      <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-2">
        <div className="flex flex-col items-start mr-auto sm:mr-0">
          <h1 className="text-white text-title-lg uppercase">{t('section-partners-title')}</h1>
          <span className="gradient-1 h-2 w-24 rounded-2xl"></span>
        </div>
        {extras}
      </div>

      {/* Content section */}
      <div className="flex flex-col w-full items-center justify-center gap-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full justify-start gap-6">
          {userPartners?.map((userPartner, index) => {
            const { isActive, isFeature, partner } = userPartner;
            return (
              <div key={`partner-${partner.id}-${index}`} className="group relative">
                {isEditing ? (
                  <FloatingActions
                    className="absolute top-2 right-2"
                    actions={getActions(userPartner)}
                    onAction={(type) => {
                      if (type === 'delete') onDelete?.(userPartner);
                      if (type === 'active') onActive?.(userPartner);
                      if (type === 'feature') onFeature?.(userPartner);
                      if (type === 'edit') onEdit?.(userPartner);
                    }}
                  />
                ) : null}
                <Link href={partner.link} target="_blank" title={partner.name}>
                  <Image 
                    src={partner.image}
                    alt={partner.name}
                    width={230}
                    height={230}
                    className="rounded-2xl bg-transparent w-full h-full"
                  />
                </Link>
              </div>
            )
          })}
        </div>
        {hasNextPage ? (
          <Button
            className="border-white text-white"
            type="outline"
            icon={<MoreIcon />}
            iconSize="xl"
            onClick={() => router.push(`${isEditing ? 'editor' : profile}/partners`)}
          >
            {t('label-show-all')}
          </Button>
        ) : null }
      </div>
    </div>
  );
};

export default Partners;
