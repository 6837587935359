import React from 'react';

const YoutubeEmbed = ({ youtubeLink = '' }: { youtubeLink: string }) => {
  const videoId = youtubeLink.indexOf("watch?v=") > -1 ? youtubeLink.split("watch?v=")[1] : youtubeLink;

  return (
    <div className="aspect-video w-full">
      <iframe
        title={videoId}
        src={`https://www.youtube.com/embed/${videoId}`}
        className="w-full h-full"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YoutubeEmbed;
