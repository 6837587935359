import React, { FC, useEffect, useState } from "react";
import Image from "next/image";
import { VideoViewProps, YoutubeVideo } from "@/domain/account/videos.interface";
import truncateText from "@/lib/utils/truncateText";
import YoutubeEmbed from "@/components/YoutubeEmbed/YoutubeEmbed";
import classNames from "classnames";
import { RequestError } from "@/graph/general.endpoints";
import { ERROR_CODES } from "@/lib/utils/constants";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import PulseRingIcon from "@apg.gg/icons/lib/PulseRingIcon";
import { useTranslations } from "next-intl";

const ThreeVideo: FC<VideoViewProps> = ({
  channelId,
}: VideoViewProps) => {
  const t = useTranslations('Profile');
  const [videos, setVideos] = useState<YoutubeVideo[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<YoutubeVideo | null>(null);
  const [isFetching, setIsFetching] = useState(false);  

  const getVideos = async () => {
    setIsFetching(true);

    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json"
        }
      };
  
      const response = await axios(`${process.env.NEXT_PUBLIC_API_PLATFORM_URL}/youtube/${channelId}`, requestOptions);
      setSelectedVideo(response.data.items[0]);
      setVideos(response.data.items.slice(0, 4));
    } catch (error: AxiosError<RequestError> | any) {
      const errorCode = (error as AxiosError<RequestError>)?.response?.data?.code as number;
      const errorMessage = ERROR_CODES(t)[errorCode];
      toast.error(errorMessage || error?.message || error?.response?.statusText);
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    getVideos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVideoClick = (video: YoutubeVideo) => {
    setSelectedVideo(video);
  }

  if (!selectedVideo && !videos?.length) return null;

  return (
    <div className="flex gap-4 w-full">
      {isFetching ? (
        <div className="flex flex-1 justify-center items-center">
          <PulseRingIcon className="text-blue text-6xl" />
        </div>
      ) : (
        <>
          {selectedVideo ? (
            <div className="flex rounded-2xl overflow-hidden flex-1">
              <YoutubeEmbed youtubeLink={selectedVideo.link} />
            </div>
          ) : null}
          <div className="flex flex-col gap-4 justify-between w-52">
            {videos.map((video: YoutubeVideo) => {
              const videoId = video.link.split('v=')[1];
              const videoThumbnail = `https://i.ytimg.com/vi/${videoId}/mqdefault.jpg`;
    
              video.id = videoId;
    
              return (
                <div
                  key={`video-${video.id}`}
                  className={classNames(
                    "w-full flex flex-col rounded-2xl overflow-hidden",
                    {
                      "border-2 border-blue": selectedVideo?.id === video.id,
                      "opacity-50": selectedVideo?.id !== video.id,
                    }
                  )}
                >
                  <div role="button" className="cursor-pointer relative" onClick={() => handleVideoClick(video)}>
                    <Image src={`${videoThumbnail || ''}`} alt={video.title} width={300} height={192} className="w-full object-cover" />
                    
                    <div className="absolute top-2 left-2">
                      <h3 className="text-sm mt-2 font-semibold w-full text-white z-30">{truncateText(video.title, 80)}</h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(ThreeVideo);
