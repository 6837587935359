import { ReactNode } from "react";
import Button from "@apg.gg/core/lib/Button";
import { PaginationResponse } from "@/domain/general.interface";
import { useParams } from "next/navigation";
import { useRouter } from 'next-nprogress-bar';
import Link from "next/link";
import Image from "next/image";
import { UserHardware } from "@/domain/account/setup.interface";
import FloatingActions from "../FloatingActions";
import { TooltipPosition } from "../FloatingActions/FloatingActions";
import TrashIcon from '@apg.gg/icons/lib/TrashIcon';
import MoreIcon from "@apg.gg/icons/lib/MoreIcon";
import { useTranslations } from "next-intl";

interface SetupProps {
  data: PaginationResponse<UserHardware>;
  extras?: ReactNode,
  isEditing?: boolean;
  onDelete?: (hardware: UserHardware) => void;
}

const Setup: React.FC<SetupProps> = ({ data, extras, isEditing, onDelete }) => {
  const router = useRouter();
  const params = useParams();
  const { profile } = params;
  const t = useTranslations('Profile');
  const { 
    items: userSetup,
    hasNextPage
  } = data || {};

  const getActions = () => {
    return [
      {
        type: 'delete',
        icon: <TrashIcon className="text-black text-base p-2" />,
        text: t('label-delete'),
        tooltipPositon: "left" as TooltipPosition
      }
    ];
  }
  
  return (
    <div id="setup" className="flex flex-col bg-black-900 p-4 lg:p-8 gap-6 rounded-2xl items-center">
      {/* Header section */}
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col">
          <h1 className="text-white text-title-lg uppercase">{t('section-setup-title')}</h1>
          <span className="gradient-1 h-2 w-24 rounded-2xl"></span>
        </div>
        {extras}
      </div>

      {/* Content section */}
      <div className="flex flex-col w-full items-center justify-center gap-4">
        <div className="grid grid-cols-1 md:grid-cols-2 w-full justify-start gap-6">
          {userSetup?.map((userHardware, index) => {
            const { hardware } = userHardware;
            const LinkComponent = hardware.link ? Link : 'div';
            return (
              <div key={`hardware-${hardware.id}-${index}`} className="group relative">
                {isEditing ? (
                  <FloatingActions
                    className="absolute top-2 right-2"
                    actions={getActions()}
                    onAction={(type) => {
                      if (type === 'delete') onDelete?.(userHardware);
                    }}
                  />
                ) : null}
                <LinkComponent href={hardware.link} target="_blank" title={hardware.name}>
                  <div className="flex gap-4 bg-black rounded-2xl p-2 items-center">
                    <Image 
                      src={hardware.image}
                      alt={hardware.name}
                      width={100}
                      height={100}
                      className="w-24 h-24 object-contain object-center rounded-xl bg-white"
                    />
                    <div className="flex flex-col">
                      <h4 className="text-title-sm uppercase font-bold text-white mb-0 mr-4">{hardware.name}</h4>
                      <span className="text-label-md text-black-400">{hardware.hardwareType.name}</span>
                    </div>
                  </div>
                </LinkComponent>
              </div>
            )
          })}
        </div>
        {hasNextPage ? (
          <Button
            className="border-white text-white"
            type="outline"
            icon={<MoreIcon />}
            iconSize="xl"
            onClick={() => router.push(`${isEditing ? 'editor' : profile}/setup`)}
          >
            {t('label-show-all')}
          </Button>
        ) : null }
      </div>
    </div>
  );
};

export default Setup;
