import '@splidejs/splide/dist/css/splide.min.css';
import React, { FC, useEffect, useRef } from "react";
import Image from "next/image";
import { FileElement } from "@/domain/account/galleries.interface";
import { Splide, SplideSlide } from "@splidejs/react-splide";

export interface MiniSliderProps {
  images: FileElement[];
}

const MiniSlider: FC<MiniSliderProps> = ({
  images
}: MiniSliderProps) => {
  const mainSliderRef = useRef<any>(null);
  const intervalIdRef = useRef<number | null>(null);
  
  const mainSliderOptions = {
    type: 'fade',
    height: '230px',
    width: '100%',
    drag: false,
    arrows: false,
    autoplay: false
  };

  const startAutoAdvance = () => {
    if (intervalIdRef.current === null) {
      intervalIdRef.current = setInterval(() => {
        mainSliderRef.current?.go('+1');
      }, 1500) as any;
    }
  };

  const stopAutoAdvance = () => {
    if (intervalIdRef.current !== null) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const handleOnHover = () => {
    startAutoAdvance();
  };

  const handleOnLeave = () => {
    stopAutoAdvance();
    mainSliderRef.current?.go(0);
  };

  useEffect(() => {
    return () => {
      stopAutoAdvance();
    };
  }, []);

  return (
    <div className="flex w-full min-w-[230px] relative" onMouseEnter={handleOnHover} onMouseLeave={handleOnLeave}>
      <Splide options={mainSliderOptions} ref={mainSliderRef} className="w-full">
        {images.map((image, index) => (
          <SplideSlide key={index} className="w-full h-[230px]">
            <Image 
              src={image.file.url} 
              className="bg-black-600 w-full min-w-[230px] h-[230px] object-cover" 
              alt={image.file.name}
              width={image.file.width} 
              height={image.file.height}
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  )
};

export default React.memo(MiniSlider);
