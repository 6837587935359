export const numify = (num: number, decimals: number = 1) => {
    num = Number(num.toString().replace(/[^0-9.]/g, ""));
    if (num < 1000) return num.toString();

    const si = [
        { v: 1e3, s: "k" },
        { v: 1e6, s: "M" },
        { v: 1e9, s: "B" },
        { v: 1e12, s: "T" },
        { v: 1e15, s: "P" },
        { v: 1e18, s: "E" },
    ];

    let index = si.length - 1;
    while (index > 0 && num < si[index].v) {
        index--;
    }

    const result = (num / si[index].v)
        .toFixed(decimals)
        .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;

    return result;
};
