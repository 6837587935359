import EmptySection from "./EmptySection";
import About from "./About";
import Achievements from "./Achievements";
import Events from "./Events";
import Gallery from "./Gallery";
import Games from "./Games";
import Partners from "./Partners";
import Setup from "./Setup";
import Videos from "./Videos";
import TotalFollowers from "./TotalFollowers";
export { 
  About,
  Achievements,
  Gallery,
  Games,
  EmptySection,
  Events,
  Partners,
  Setup,
  Videos,
  TotalFollowers
};