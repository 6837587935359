"use client";

import { ReactNode } from "react";
import { useTranslations } from "next-intl";
import { useParams } from "next/navigation";
import { SocialFollowersCount, UserSectionData } from "@/domain/account/socials.interface";
import Spinner from "../ui/Spinner";
import { numify } from "@/lib/utils/numify";
import TiktokIcon from '@apg.gg/icons/lib/TiktokIcon';
import InstagramIcon from '@apg.gg/icons/lib/InstagramIcon';
import YoutubeIcon from '@apg.gg/icons/lib/YoutubeIcon';
import TwitterIcon from '@apg.gg/icons/lib/TwitterIcon';
import TwitchIcon from '@apg.gg/icons/lib/TwitchIcon';
import FacebookIcon from '@apg.gg/icons/lib/FacebookIcon';

interface TotalFollowersProps {
  data: SocialFollowersCount;
  isLoading: boolean;
  userSectionData?: UserSectionData;
  extras?: ReactNode
}

const TotalFollowers: React.FC<TotalFollowersProps> = ({ data, isLoading, userSectionData, extras }) => {
  const t = useTranslations('Profile');
  const params = useParams();
  const { locale } = params;

  const { data: providers } = userSectionData || {};

  const {
    totalFollowers,
    tiktokFollowers,
    instagramFollowers,
    youtubeFollowers,
    twitchFollowers,
    twitterFollowers,
    facebookFollowers
  } = data || {};

  return (
    <div className="flex flex-col bg-black-900 p-4 lg:p-8 gap-6 rounded-2xl items-center">
      {/* Header section */}
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col">
          <h1 className="text-white text-title-lg uppercase">{t('section-total-followers-title')}</h1>
          <span className="gradient-1 h-2 w-24 rounded-2xl"></span>
        </div>
        {extras}
      </div>

      {/* Content section */}
      <div className="flex w-full">
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-96">
            <Spinner />
          </div>
        ) : (
          <div className="flex gap-6 w-full items-start justify-between">
            <div className="flex flex-col gap-0 min-w-16">
              <div className="text-white text-headline-lg">{numify(totalFollowers)}</div>
              <div className="text-white-300 text-title-sm">Total Followers</div>
            </div>

            <div className="flex gap-6">
              {tiktokFollowers > 0 ? (
                <div className="flex items-center justify-center gap-2">
                  <div className="w-8 h-8 flex items-center justify-center rounded-lg bg-[#69C9D0]">
                    <TiktokIcon className="text-white text-xl" />
                  </div> 
                  <div className="text-white text-headline-lg">{numify(tiktokFollowers)}</div>
                </div>
              ) : null}

              {twitchFollowers > 0 ? (
                <div className="flex items-center justify-center gap-2">
                  <div className="w-8 h-8 flex items-center justify-center rounded-lg bg-[#9146FF]">
                    <TwitchIcon className="text-white text-xl" />
                  </div> 
                  <div className="text-white text-headline-lg">{numify(twitchFollowers)}</div>
                </div>
              ) : null}

              {twitterFollowers > 0 ? (
                <div className="flex items-center justify-center gap-2">
                  <div className="w-8 h-8 flex items-center justify-center rounded-lg bg-[#1DA1F2]">
                    <TwitterIcon className="text-white text-xl" />
                  </div> 
                  <div className="text-white text-headline-lg">{numify(twitterFollowers)}</div>
                </div>
              ) : null}

              {providers?.some(({ provider }: { provider: string }) => provider === 'instagram') ? (
                <div className="flex items-center justify-center gap-2">
                  <div className="w-8 h-8 flex items-center justify-center rounded-lg bg-[#E1306C]">
                    <InstagramIcon className="text-white text-xl" />
                  </div> 
                  <div className="text-white text-headline-lg">{numify(instagramFollowers)}</div>
                </div>
              ) : null}

              {providers?.some(({ provider, channelId }: { provider: string, channelId: string }) => provider === 'youtube' && channelId) ? (
                <div className="flex items-center justify-center gap-2">
                  <div className="w-8 h-8 flex items-center justify-center rounded-lg bg-[#FF0000]">
                    <YoutubeIcon className="text-white text-xl" />
                  </div> 
                  <div className="text-white text-headline-lg">{numify(youtubeFollowers)}</div>
                </div>
              ) : null}

              {facebookFollowers > 0 ? (
                <div className="flex items-center justify-center gap-2">
                  <div className="w-8 h-8 flex items-center justify-center rounded-lg bg-[#1877F2]">
                    <FacebookIcon className="text-white text-xl" />
                  </div> 
                  <div className="text-white text-headline-lg">{numify(facebookFollowers)}</div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TotalFollowers;
