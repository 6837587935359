import { ReactNode } from "react";
import CardEvent from '@apg.gg/core/lib/CardEvent';
import Button from "@apg.gg/core/lib/Button";
import { PaginationResponse } from "@/domain/general.interface";
import { useParams } from "next/navigation";
import { useRouter } from 'next-nprogress-bar';
import { Event } from "@/domain/account/events.interface";
import EditIcon from "@apg.gg/icons/lib/EditIcon";
import StarFillIcon from "@apg.gg/icons/lib/StarFillIcon";
import StarOutlineIcon from "@apg.gg/icons/lib/StarOutlineIcon";
import TrashIcon from "@apg.gg/icons/lib/TrashIcon";
import VisibilityIcon from "@apg.gg/icons/lib/VisibilityIcon";
import VisibilityOffIcon from "@apg.gg/icons/lib/VisibilityOffIcon";
import FloatingActions, { TooltipPosition } from "../FloatingActions/FloatingActions";
import { useTranslations } from "next-intl";
import Link from "next/link";
import EventsCarousel from "./EventsCarousel";

interface EventsProps {
  data: PaginationResponse<Event>;
  extras?: ReactNode,
  isEditing?: boolean;
  onEdit?: (event: Event) => void;
  onDelete?: (event: Event) => void;
  onActive?: (event: Event) => void;
  onFeature?: (event: Event) => void;
}

const Events: React.FC<EventsProps> = ({ data, extras, isEditing, onEdit, onDelete, onActive, onFeature }) => {
  const router = useRouter();
  const params = useParams();
  const { profile, locale } = params;
  const t = useTranslations();
  const { 
    items: events,
    hasNextPage
  } = data || {};

  const getActions = (userEvent: Event) => {
    const { isActive, isFeature } = userEvent;
    const iconClassname = "text-black text-base p-2";

    return [
      {
        type: 'edit',
        icon: <EditIcon className={iconClassname} />,
        text: t('Profile.label-edit'),
        tooltipPositon: "left" as TooltipPosition
      },
      {
        type: 'feature',
        icon: isFeature ? <StarFillIcon className={iconClassname} /> : <StarOutlineIcon className={iconClassname} />,
        text: isFeature ? t('Profile.label-no-featured') : t('Profile.label-featured'),
        tooltipPositon: "left" as TooltipPosition
      },
      {
        type: "active",
        icon: isActive ? <VisibilityOffIcon className={iconClassname} /> : <VisibilityIcon className={iconClassname} />,
        text: isActive ? t('Profile.label-hide') : t('Profile.label-show'),
        tooltipPositon: "left" as TooltipPosition
      },
      {
        type: 'delete',
        icon: <TrashIcon className={iconClassname} />,
        text: t('Profile.label-delete'),
        tooltipPositon: "left" as TooltipPosition
      }
    ];
  }

  return (
    <div className="flex flex-col bg-black-900 p-4 lg:p-8 gap-6 rounded-2xl items-center">
      {/* Header section */}
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col">
          <h1 className="text-white text-title-lg uppercase">{t('Profile.section-events-title')}</h1>
          <span className="gradient-1 h-2 w-24 rounded-2xl"></span>
        </div>
        {extras}
      </div>

      {/* Content section */}
      <div className="flex flex-col w-full items-center justify-center gap-4">
        <div className="hidden lg:grid lg:grid-cols-3 w-full justify-start gap-6">
          {events?.map((event, index) => {
            const { game, eventType, eventTag, eventCategory, profileType, description } = event;
            return (
              <div key={`event-${event.id}-${index}`} className="group relative">
                {isEditing ? (
                  <FloatingActions
                    className="absolute top-2 right-2"
                    actions={getActions(event)}
                    onAction={(type) => {
                      if (type === 'edit') onEdit?.(event);
                      if (type === 'delete') onDelete?.(event);
                      if (type === 'active') onActive?.(event);
                      if (type === 'feature') onFeature?.(event);
                    }}
                  />
                ) : null}
                <CardEvent
                  className="bg-black"
                  id={event.id}
                  title={event.title}
                  slug={event.slug}
                  banner={event.image}
                  link={event.link || undefined}
                  shortDescription={'a'}
                  shortRichDescription={description}
                  date={new Date(event.date).toISOString()}
                  game={game ? game.name : 'General'}
                  profileType={profileType}
                  type={eventType.name}
                  tag={eventTag.name}
                  category={eventCategory.name}
                  locale={locale}
                  owner={event.owner}
                  linkComponent={Link}
                />
              </div>
            )
          })}
        </div>

        <div className="flex w-full lg:hidden">
          <EventsCarousel
            events={events}
            isEditing={isEditing || false}
            getActions={getActions}
            onEdit={(event) => onEdit?.(event)}
            onDelete={(event) => onDelete?.(event)}
            onActive={(event) => onActive?.(event)}
            onFeature={(event) => onFeature?.(event)}
          />
        </div>

        {hasNextPage ? (
          <Button
            type="outline"
            fontSize="sm"
            onClick={() => router.push(`${isEditing ? 'editor' : profile}/events`)}
          >
            {t('Profile.label-show-all')}
          </Button>
        ) : null }
      </div>
    </div>
  );
};

export default Events;
