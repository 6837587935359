import React, { FC, useEffect, useState } from "react";
import { VideoViewProps, YoutubeVideo } from "@/domain/account/videos.interface";
import truncateText from "@/lib/utils/truncateText";
import Drawer from "@apg.gg/core/lib/Drawer";
import YoutubeEmbed from "@/components/YoutubeEmbed/YoutubeEmbed";
import { RequestError } from "@/graph/general.endpoints";
import { ERROR_CODES } from "@/lib/utils/constants";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import PulseRingIcon from "@apg.gg/icons/lib/PulseRingIcon";
import { useTranslations } from "next-intl";

const OnlyList: FC<VideoViewProps> = ({
  channelId,
}: VideoViewProps) => {
  const t = useTranslations('Profile');
  const [videos, setVideos] = useState<YoutubeVideo[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<YoutubeVideo | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState(false);

  const getVideos = async () => {
    setIsFetching(true);

    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json"
        }
      };
  
      const response = await axios(`${process.env.NEXT_PUBLIC_API_PLATFORM_URL}/youtube/${channelId}`, requestOptions);
      setVideos(response.data.items.slice(0, 8));
    } catch (error: AxiosError<RequestError> | any) {
      const errorCode = (error as AxiosError<RequestError>)?.response?.data?.code as number;
      const errorMessage = ERROR_CODES(t)[errorCode];
      toast.error(errorMessage || error?.message || error?.response?.statusText);
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    getVideos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  const handleVideoClick = (video: YoutubeVideo) => {
    setSelectedVideo(video);
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setSelectedVideo(null);
    setShowModal(false);
  }

  if (!videos?.length) return null;

  return (
    <div className="flex gap-4 w-full">
      {isFetching ? (
        <div className="flex flex-1 justify-center items-center">
          <PulseRingIcon className="text-blue text-6xl" />
        </div>
      ) : (
        <>
          <Drawer 
            isOpen={showModal} 
            onClose={handleCloseModal}
            header={<h4 className="font-akira text-base">{selectedVideo?.title}</h4>}
            disableClickOutsideToClose={true}
            content={<YoutubeEmbed youtubeLink={selectedVideo?.link as string} />}
          />
          <div className="flex flex-col gap-2 justify-between w-full">
            {videos.map((video: YoutubeVideo) => {
              return (
                <div
                  key={`video-${video.id}`}
                  className="w-full flex cursor-pointer gap-4 bg-black p-3 rounded-2xl"
                  onClick={() => handleVideoClick(video)}
                >
                  <div className="flex-1">
                    <h3 className="text-body-lg font-semibold w-full text-white">{truncateText(video.title, 80)}</h3>
                    <p className="text-sm text-black-500">{truncateText(video.description, 120)}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(OnlyList);
