import { ReactNode, useRef } from "react";
import { UserGame } from "@/domain/account/profile.interface";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useTranslations } from "next-intl";
import GameCard from "../GameCard";
import useWindowSize from "@apg.gg/core/lib/useWindowSize";

import "@splidejs/splide/dist/css/splide.min.css";

interface GamesProps {
  data: UserGame[];
  extras?: ReactNode
}

const Games: React.FC<GamesProps> = ({ data: userGames, extras }) => {
  const t = useTranslations("Profile");
  const { isXs, isSm } = useWindowSize();
  const mainSliderRef = useRef<any>(null);

  const mainSliderOptions = {
    type: "slide",
    height: isXs ? "355px" : isSm ? "300px" : "320px",
    width: "100%",
    fixedWidth: isXs ? "70%" : isSm ? "160px" : "200px",
    pagination: false,
    gap: "1rem",
    perPage: isXs ? 1 : isSm ? 3 : 4,
    perMove: 1,
    padding: "0"
  };

  return (
    <div className="flex flex-col bg-black-900 p-4 lg:p-8 gap-6 rounded-2xl items-center">
      {/* Header section */}
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col">
          <h1 className="text-white text-title-lg uppercase">{t("section-games-title")}</h1>
          <span className="gradient-1 h-2 w-24 rounded-2xl"></span>
        </div>
        {extras}
      </div>

      {/* Content section */}
      <div className="flex w-full">
        <Splide options={mainSliderOptions} ref={mainSliderRef} className="games-carousel">
          {userGames?.map((userGame, index) => {
            return (
              <SplideSlide key={`game-slide-${userGame.id}-${index}`} className="cursor-pointer overflow-hidden">
                <GameCard userGame={userGame} />
              </SplideSlide>
            )
          })}
        </Splide>
      </div>
    </div>
  );
};

export default Games;
